<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
          <h3 class="pt-1">
            {{ lista_campos != "" ? "Editar" : "Criar" }} Taxa Eventos
          </h3>
          <div class="col-md-8">
            <treeselect
              disabled
              :multiple="false"
              :options="lista_eventos"
              placeholder="Selecione o evento..."
              v-model="form.eventos_id"
            />
          </div>
        </div>
        <div class="card-body table-responsive">
          <form action="">
            <div class="form-group row">
              <div class="col-md-4">
                <label class="col-md-12">pix:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="pix"
                  placeholder="Digite a Pix..."
                  v-mask="variableWithMaskP"
                  @input="valorFormatP"
                />
                <div class="md-radio-inline">
                  <div class="space d-flex">
                    <b-form-radio
                      @input="valorFormatP"
                      :inline="true"
                      value="1"
                      v-model="form.pix_tipo"
                    >
                      %
                    </b-form-radio>

                    <b-form-radio
                      @input="valorFormatP"
                      :inline="true"
                      value="2"
                      v-model="form.pix_tipo"
                    >
                      Valor
                    </b-form-radio>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Cartão Debito:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="cartao_debito"
                  placeholder="Digite a Cartão Debito..."
                  v-mask="variableWithMaskC"
                  @input="valorFormatC"
                />
                <div class="md-radio-inline">
                  <div class="space d-flex">
                    <b-form-radio
                      @input="valorFormatC"
                      :inline="true"
                      value="1"
                      v-model="form.c_tipo"
                    >
                      %
                    </b-form-radio>

                    <b-form-radio
                      @input="valorFormatC"
                      :inline="true"
                      value="2"
                      v-model="form.c_tipo"
                    >
                      Valor
                    </b-form-radio>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <label class="col-md-12">parcelas:*</label>

                <select class="form-control" v-model="form.parcela">
                  <option disabled value="0">Escolha uma Parcela</option>
                  <option v-for="i in 12" :key="i" :value="i">
                    {{ i }} Parcela
                  </option>
                </select>
                <div class="md-radio-inline">
                  <div class="space d-flex">
                    <b-form-radio
                      @input="valorFormat1"
                      :inline="true"
                      value="1"
                      v-model="form.parcela_tipo"
                    >
                      %
                    </b-form-radio>

                    <b-form-radio
                      @input="valorFormat1"
                      :inline="true"
                      value="2"
                      v-model="form.parcela_tipo"
                    >
                      Valor
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div v-for="j in form.parcela" :key="j" class="col-md-3">
                <label class="col-md-12">valor:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="porcent[j - 1]"
                  :placeholder="'Valor da parcela ' + j"
                  v-mask="variableWithMask[j - 1]"
                  @input="valorFormat(j - 1)"
                />
              </div>
            </div>

            <!-- 
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite a Descricao..."
                />
              </div>
            </div> -->
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import EventoService from "@/core/services/evento.service";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        pix: null,
        cartao_debito: null,

        parcela: 0,
        porcent: [],
        pix_tipo: 2,
        c_tipo: 2,
        parcela_tipo: 2,

        status: 1,
        eventos_id: EventoService.getEventoId(),
      },
      porcent: [],
      pix: null,
      cartao_debito: null,
      variableWithMask: [],
      variableWithMaskP: "",
      variableWithMaskC: "",

      verif: false,
    };
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.taxaEventos.mensagem_alert;
    },
    lista_eventos() {
      return EventoService.getEventos().map((cat) => ({
        id: cat.id,
        label:
          cat.id +
          " - " +
          cat.nome_evento +
          " - " +
          (cat.status == 1 ? "ativo" : "inativo"),
      }));
    },
  },
  created() {
    this.preenxerCampos();
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` un Setor no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.format_campos();
      // console.log(  this.form.porcent)

      await this.$store.dispatch("taxaEventos/create_taxa_eventos", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "taxaEventos",
      });
    },
    async update() {
      this.verif = true;
      await this.format_campos();
      await this.$store.dispatch("taxaEventos/update_taxa_eventos", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "taxaEventos",
      });
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        (this.porcent = JSON.parse(this.lista_campos.porcent)),
          (this.cartao_debito = this.lista_campos.cartao_debito + "  "),
          (this.pix = this.lista_campos.pix + "  "),
          (this.form = {
            id: this.lista_campos.id,
            parcela: this.lista_campos.parcela,

            pix_tipo: this.lista_campos.pix_tipo,
            c_tipo: this.lista_campos.c_tipo,
            parcela_tipo: this.lista_campos.parcela_tipo,

            porcent: JSON.parse(this.lista_campos.porcent),
            eventos_id: this.lista_campos.eventos_id
              ? this.lista_campos.eventos_id
              : EventoService.getEventoId(),
          });

      this.valorFormatC();
      this.valorFormatP();
      this.valorFormat1();
    },

    valorFormatC() {
      let pa = "";
      for (let i = 0; i < this.cartao_debito.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      if (this.form.c_tipo == 2) this.variableWithMaskC = "R$" + pa + "#,##";
      else this.variableWithMaskC = pa + "#,##" + " %";
    },
    valorFormatP() {
      let pa = "";
      for (let i = 0; i < this.pix.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      if (this.form.pix_tipo == 2) this.variableWithMaskP = "R$" + pa + "#,##";
      else this.variableWithMaskP = pa + "#,##" + " %";
    },
    valorFormat(index) {
      let pa = "";
      for (let i = 0; i < this.porcent[index].length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      if (this.form.parcela_tipo == 2)
        this.variableWithMask[index] = "R$" + pa + "#,##";
      else this.variableWithMask[index] = pa + "#,##" + " %";
      //  if (this.porcent[index].length < 3) {
      //   this.variableWithMask[index]= '#,00 %'
      //     }
      //     if (this.porcent[index].length < 4) {
      //   this.variableWithMask[index]= '##,00 %'
      //     }

      if (this.porcent[index].length == 2) {
        this.variableWithMask[index] = "#,00 %";
      }
      if (this.porcent[index].length == 3) {
        this.variableWithMask[index] = "##,00 %";
      }
    },
    valorFormat1() {
      for (let index = 0; index <= this.form.parcela; index++) {
        let pa = "";

        for (let i = 0; i < this.porcent[index].length; i++) {
          if (i > 5) {
            pa = pa + "#";
          }
        }

        if (this.form.parcela_tipo == 2)
          this.variableWithMask[index] = "R$" + pa + "#,##";
        else this.variableWithMask[index] = pa + "#,##" + " %";
      }
    },
    format_campos() {
      let a = this.porcent;
      this.form.porcent = [];
      if (this.form.parcela_tipo == 2) {
        a.forEach((element, index) => {
          this.form.porcent.push(
            parseFloat(element.replace("R$", "").replace(",", "."))
          );
        });
      } else {
        a.forEach((element, index) => {
          this.form.porcent.push(
            parseFloat(element.replace(" %", "").replace(",", "."))
          );
        });
      }
      if (this.form.pix_tipo == 2) {
        this.form.pix = parseFloat(
          this.pix.replace("R$", "").replace(",", ".")
        );
      } else {
        this.form.pix = parseFloat(
          this.pix.replace(" %", "").replace(",", ".")
        );
      }

      if (this.form.c_tipo == 2) {
        this.form.cartao_debito = parseFloat(
          this.cartao_debito.replace("R$", "").replace(",", ".")
        );
      } else {
        this.form.cartao_debito = parseFloat(
          this.cartao_debito.replace(" %", "").replace(",", ".")
        );
      }
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: #8cdde2;
}
</style>